module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"es-co","repositoryName":"tropicalbp","path":"/preview","previews":true,"omitPrismicScript":false,"langs":["es-co","en-us","fr-fr"],"pages":[{"type":"Homepage","match":"/:lang?","path":"/:lang?","component":"/opt/build/repo/src/templates/Homepage.js","langs":["es-co","en-us","fr-fr"]},{"type":"Page","match":"/:lang?/:uid","path":"/:lang?","component":"/opt/build/repo/src/templates/Page.js","langs":["es-co","en-us","fr-fr"]},{"type":"Solution","match":"/solution/:lang?/:uid","path":"/solution/:lang?","component":"/opt/build/repo/src/templates/Solution.js","langs":["es-co","en-us","fr-fr"]},{"type":"Solution","match":"/solution/:lang?/:uid","path":"/solution/:lang?","component":"/opt/build/repo/src/templates/Solution.js","langs":["es-co","en-us","fr-fr"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tropical Building Physics","short_name":"TropicalBP","description":"Ingeniería, arquitectura, y urbanismo acordes al clima","lang":"es","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/assets/imgs/logo.png","localize":[{"start_url":"/en-us/","lang":"en","name":"Tropical Building Physics","short_name":"TropicalBP","description":"Climate responsive Engineering, Architecture, and Urban Planning"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8e103267bb21bc3f93dc97d679f70cf0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
